import React, { Suspense, lazy } from 'react'
import { tw } from 'workfront-twind'
const Phoenix = lazy(() => import('./Phoenix.jsx'))
const NotificationService = lazy(() => import('./NotificationService.jsx'))

export default function AlertingTests() {
  return (
    <div className={tw`m-auto w-1/4 pt-6 perf-slotted-content`}>
      <Suspense fallback={'Loading...'}>
        <Phoenix />
        <NotificationService />
      </Suspense>
    </div>
  )
}
