import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { PageContentSlot } from '@wf-mfe/layout'
import { UnsavedChangesPromptProvider } from '@wf-mfe/navigation'
import AlertingTests from './AlertingTests/AlertingTests'
import { PerformanceInfo } from './PerformanceInfo'
import { ReactRouterUpgrade, UpgradeDetailsPage } from './ReactRouterUpgrade'

export default function RootComponent() {
  return (
    <PageContentSlot mfeName="@wf-mfe/perf">
      <UnsavedChangesPromptProvider>
        <Routes>
          <Route
            path={`react-router-upgrade/:ID/:subpath`}
            element={<UpgradeDetailsPage />}
          />
          <Route
            path={`react-router-upgrade/:ID`}
            element={<UpgradeDetailsPage />}
          />
          <Route
            path={`react-router-upgrade`}
            element={<ReactRouterUpgrade />}
          />
          <Route path={`alerting-tests`} element={<AlertingTests />} />
          <Route path="*" element={<PerformanceInfo />} />
        </Routes>
      </UnsavedChangesPromptProvider>
    </PageContentSlot>
  )
}
